<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_810_4743"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="24"
    >
      <rect
        x="0.703125"
        width="24"
        height="24"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_810_4743)">
      <path
        d="M9.66689 16.1536L12.5562 14.4109L15.4456 16.1766L14.6889 12.8744L17.2343 10.673L13.8863 10.3749L12.5562 7.25626L11.2262 10.352L7.87824 10.6501L10.4236 12.8744L9.66689 16.1536ZM12.5562 16.5664L8.74963 18.8595C8.58147 18.9666 8.40566 19.0124 8.22221 18.9971C8.03876 18.9818 7.87824 18.9207 7.74065 18.8137C7.60306 18.7067 7.49605 18.5729 7.41961 18.4124C7.34318 18.2519 7.32789 18.0722 7.37375 17.8735L8.38273 13.5395L5.01182 10.6272C4.85894 10.4896 4.76339 10.3329 4.72517 10.1571C4.68696 9.98127 4.69842 9.80929 4.75957 9.64112C4.82072 9.47296 4.91245 9.33537 5.03475 9.22836C5.15705 9.12135 5.32521 9.05255 5.53924 9.02198L9.98793 8.63214L11.7078 4.55035C11.7842 4.3669 11.9027 4.22931 12.0632 4.13759C12.2237 4.04586 12.3881 4 12.5562 4C12.7244 4 12.8888 4.04586 13.0493 4.13759C13.2098 4.22931 13.3283 4.3669 13.4047 4.55035L15.1246 8.63214L19.5733 9.02198C19.7873 9.05255 19.9554 9.12135 20.0777 9.22836C20.2 9.33537 20.2918 9.47296 20.3529 9.64112C20.4141 9.80929 20.4255 9.98127 20.3873 10.1571C20.3491 10.3329 20.2536 10.4896 20.1007 10.6272L16.7298 13.5395L17.7387 17.8735C17.7846 18.0722 17.7693 18.2519 17.6929 18.4124C17.6164 18.5729 17.5094 18.7067 17.3718 18.8137C17.2343 18.9207 17.0737 18.9818 16.8903 18.9971C16.7068 19.0124 16.531 18.9666 16.3629 18.8595L12.5562 16.5664Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'IcFavourite',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
